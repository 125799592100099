import React, { ChangeEvent, useState } from "react";
import { ProductType } from "../../../types/Products";
import { useParams } from "react-router-dom";
import InputComponent from "../../../components/Input/InputComponent";
import { PostApi } from "../../../services/Api";
interface addProduct {
  initialValue: ProductType;
  clearProduct: () => void;
}
const AddProduct = ({
  initialValue,
  clearProduct,
}: addProduct) => {
  let { id } = useParams();
  let [product, setProduct] =
    useState<ProductType>(initialValue);
  const handleChange = (
    element: ChangeEvent<HTMLInputElement>,
  ) => {
    let { id, value } = element.target;
    setProduct({ ...product, [id]: value });
  };
  const handleSubmit = async () => {
    if (
      !product.originalManufacturingValue ||
      !product.manufacturingValue ||
      !product.acquisitionValue ||
      !product.standardAmount
    )
      return;

    let {
      productId,
      originalManufacturingValue,
      manufacturingValue,
      acquisitionValue,
      standardAmount,
    } = product;
    let response = await PostApi("groups/products", {
      groupId: id,
      productId,
      originalManufacturingValue: Number(
        (Number(originalManufacturingValue) * 100).toFixed(
          0,
        ),
      ),
      manufacturingValue: Number(
        (Number(manufacturingValue) * 100).toFixed(0),
      ),
      acquisitionValue: Number(
        (Number(acquisitionValue) * 100).toFixed(0),
      ),
      standardAmount: Number(
        (Number(standardAmount) * 100).toFixed(0),
      ),
    });
    if (response.status === 201) {
      clearProduct();
    }
  };
  return (
    <>
      <section>
        <div className="value">
          <InputComponent
            label="Custo de fabricação:"
            id="originalManufacturingValue"
            type="number"
            step="0.01"
            value={product.originalManufacturingValue || 0}
            onChange={handleChange}
          />
          <InputComponent
            label="Valor de venda fábrica:"
            id="manufacturingValue"
            type="number"
            step="0.01"
            value={product.manufacturingValue}
            onChange={handleChange}
          />
        </div>
        <div className="value">
          <InputComponent
            label="Custo de aquisição loja:"
            id="acquisitionValue"
            type="number"
            step="0.01"
            value={product.acquisitionValue}
            onChange={handleChange}
          />
          <InputComponent
            label="Lucro fabrica:"
            id=""
            type="number"
            step="0.01"
            value={(
              product.manufacturingValue -
              (product.originalManufacturingValue || 0)
            ).toFixed(2)}
            disabled
          />
        </div>
        <div className="value">
          <InputComponent
            label="Preço de venda loja:"
            id="standardAmount"
            type="number"
            step="0.01"
            value={product.standardAmount}
            onChange={handleChange}
          />
          <InputComponent
            label="Lucro loja:"
            id=""
            type="number"
            step="0.01"
            value={(
              product.standardAmount -
              product.acquisitionValue
            ).toFixed(2)}
            disabled
          />
        </div>
      </section>
      <footer>
        <button className="third">Cancelar</button>
        <button
          disabled={
            !product.originalManufacturingValue ||
            !product.manufacturingValue ||
            !product.acquisitionValue ||
            !product.standardAmount
          }
          onClick={handleSubmit}
        >
          Salvar
        </button>
      </footer>
    </>
  );
};

export default AddProduct;
